import React from "react";

export default function Buttons({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) {
  return (
    <button className="button" onClick={onClick}>
      {text}
    </button>
  );
}
