import FightingScreenHeader from "./FightingScreenHeader";
import Actions from "./Actions";
import QuickJab from "../assets/moves/QUICKJAB.png";
import StrongPunch from "../assets/moves/STRONGPUNCH.png";
import Defence from "../assets/moves/Defend.png";

import { motion } from "framer-motion";
import { useRecoilState, useRecoilValue } from "recoil";
import { gameStateAtom } from "../state/game";
import { aiMoveState, playMoveState } from "../recoil/moves";
import { userSelectedCharacterAtom } from "../state/character";
import ShareModal from "./ShareModal";
import LooseScreen from "./LooseScreen";
import { useEffect, useState } from "react";

const imagesArray = [QuickJab, StrongPunch, Defence];

const LinearMove = (rev: boolean) => ({
  x: [0, rev ? -100 : 100, 0],
  y: [0, 0, 0],

  transition: {
    duration: 1.7,
    repeat: Infinity,
    ease: "linear",
  },
});

const Lose = {
  rotate: [0, 0, 90, 90, 0, 0],
  y: [0, 0, 80, 80, 0, 0],
  x: [0, "-20vw", "-20vw", "-20vw", "-20vw", 0],
  transition: {
    duration: 1.7,
    ease: [0.7, 0, 1, 1],
  },
};

const LoseLeft = {
  rotate: [0, 0, -90, -90, 0, 0],
  y: [0, -90, -90, -90, -90, 0],
  x: [0, "25vw", "25vw", "25vw", "25vw", 0],
  transition: {
    duration: 1.7,
    ease: [0.7, 0, 1, 1],
  },
};

const Win = {
  rotate: 0,
  x: ["0vw", "23vw", "23vw", "23vw", "23vw", "0vw"],
  y: [0, -100, -160, -100, -160, 0],
  transition: {
    duration: 1.7,
    ease: "easeInOut",
  },
};

const WinRight = {
  rotate: 0,
  x: ["0vw", "-20vw", "-20vw", "-20vw", "-20vw", "0vw"],
  y: [0, -70, -140, -70, -140, 0],
  transition: {
    duration: 2,
    ease: "easeInOut",
  },
};

export default function FightingScreen() {
  const showInfo = true;
  const gameState = useRecoilValue(gameStateAtom);
  const [aiMove, updateAiMove] = useRecoilState(aiMoveState);
  const [playerMove, updatePlayerMove] = useRecoilState(playMoveState);
  const playerCharacter = useRecoilValue(userSelectedCharacterAtom);
  const hasPlayerWon = gameState.hasGameEnded && gameState.hasPlayerWon;
  const hasPlayerLost = gameState.hasGameEnded && !gameState.hasPlayerWon;
  const [playerAnimation, setPlayerAnimation] = useState("resting");
  const [aiAnimation, setAiAnimation] = useState("resting");

  useEffect(() => {
    if (gameState.lastMoveResult) {
      if (gameState.lastMoveResult.result === "win") {
        setPlayerAnimation("win");
        setAiAnimation("lose");
      } else if (gameState.lastMoveResult.result === "lose") {
        setPlayerAnimation("lose");
        setAiAnimation("win");
      } else if (gameState.lastMoveResult.result === "draw") {
        setTimeout(() => {
          updateAiMove(undefined);
          updatePlayerMove(undefined);
        }, 1000);
      }
    }
  }, [gameState.lastMoveResult]);

  return (
    <div className="fightingScreen">
      {hasPlayerWon && <ShareModal winner={playerCharacter} />}
      {hasPlayerLost && <LooseScreen />}
      <FightingScreenHeader />
      {!gameState.hasGameEnded && <Actions />}
      <motion.div
        animate={aiAnimation}
        variants={{
          resting: LinearMove(false),
          win: Win,
          lose: LoseLeft,
        }}
        className="leftFighter"
        onAnimationComplete={(x) => {
          if (x === aiAnimation) {
            setAiAnimation("resting");
            updateAiMove(undefined);
          }
        }}
      >
        <motion.img
          className="leftFighterImg"
          src={gameState.aiCharacter?.bodyImg}
          alt="fighter1"
        />
      </motion.div>
      {aiMove !== undefined ? (
        <motion.div
          initial={{ left: "-10vw" }}
          animate={{ left: aiMove !== undefined ? "25%" : "-10vw" }}
          transition={{
            left: {
              duration: 0.4,
              ease: "easeIn",
            },
          }}
          className="smMove"
        >
          <img src={imagesArray[aiMove]} alt="move" />
        </motion.div>
      ) : null}
      <div className="rightFighter">
        <motion.img
          className="rightFighterImg"
          animate={playerAnimation}
          variants={{
            resting: LinearMove(true),
            win: WinRight,
            lose: Lose,
          }}
          transition={{
            repeatType: "loop",
          }}
          onAnimationComplete={(x) => {
            if (x === playerAnimation) {
              setPlayerAnimation("resting");
              updatePlayerMove(undefined);
            }
          }}
          src={gameState.playerCharacter?.bodyImg}
          alt="fighter2"
        />
      </div>

      {playerMove !== undefined ? (
        <motion.div
          initial={{ right: "-20vw" }}
          animate={{ right: playMoveState !== undefined ? "25vw" : "-20vw" }}
          transition={{
            right: {
              duration: 0.4,
              ease: "easeIn",
            },
          }}
          className="fighterAction"
        >
          <img src={imagesArray[playerMove]} alt="move" />
        </motion.div>
      ) : null}

      {showInfo && (
        <>
          <div className="info">
            <div className="infoHeader">Instructions</div>
            KICK BEATS DEFENCE <br />
            PUNCH BEATS KICK
            <br />
            DEFENCE BEATS PUNCH
            <br />
          </div>
        </>
      )}
    </div>
  );
}
