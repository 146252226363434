import styles from "../../styles/character.module.css";

import Spacer from "../../components/Spacer";
import Header from "../../components/Header";
import { userSelectedCharacterAtom } from "../../state/character";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { CHARACTERS } from "../../constants/characters";
import { useGame } from "../engine/useGameEngine";

import bellSfx from "../../assets/sounds/bell.mp3";
import useSound from "use-sound";

function CharacterOption({
  img,
  name,
  isSelected,
  handleClick,
  handleHover,
  handleLeave,
}: {
  img: string;
  name: string;
  isSelected: boolean;
  handleClick: () => void;
  handleHover: () => void;
  handleLeave: () => void;
}) {
  const nameStyle = name.length > 6 ? { fontSize: "1.5rem" } : undefined;

  return (
    <div
      className={`${styles.characterWrapper} ${
        isSelected ? styles.selected : ""
      }`}
      onClick={handleClick}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    >
      <div className={styles.imgWrapper}>
        <img src={img} alt={name} />
      </div>
      <div className={styles.hr}></div>
      <div className={styles.name} style={nameStyle}>
        {name}
      </div>
    </div>
  );
}

export default function CharacterSelection() {
  const { startGame } = useGame();
  const [hoveredCharacterImg, setHoveredCharacterImg] = useState<string>();
  const [selectedCharacter, setUserCharacter] = useRecoilState(
    userSelectedCharacterAtom
  );

  // useEffect(() => {
  //   setUserCharacter(CHARACTERS[0]);
  // }, []);

  const [playBell] = useSound(bellSfx);

  return (
    <div className="content">
      <div>
        <Spacer height={60} />
        <Header />
      </div>
      <div className={styles.heading}>CHARACTER SELECTION</div>
      <div className={styles.charactersContainer}>
        <div className={styles.charGrid}>
          {CHARACTERS.map((char, index) => (
            <CharacterOption
              key={index}
              isSelected={char.name === selectedCharacter?.name}
              img={char.faceImg}
              name={char.name}
              handleClick={() => {
                setUserCharacter(char);
                startGame(char);
                setHoveredCharacterImg(char.bodyImg);
                playBell();
                // setActiveScreen(ScreensEnum.FIGHT_SCREEN);
              }}
              handleHover={() => setHoveredCharacterImg(char.bodyImg)}
              handleLeave={() =>
                setHoveredCharacterImg(
                  selectedCharacter ? selectedCharacter.bodyImg : undefined
                )
              }
            />
          ))}
        </div>
        <div className={styles.charDisplay}>
          {hoveredCharacterImg && (
            <img src={hoveredCharacterImg} alt="character" />
          )}
        </div>
      </div>
    </div>
  );
}
