import { Character } from "../../state/character";
import { CHARACTERS } from "../../constants/characters";

let round: number = 0;
let userRoundScore: number = 0;
let aiRoundScore: number = 0;
let userWonRounds: number = 0;
let aiWonRounds: number = 0;
let hasGameEnded: boolean = false;
let aiMove: Moves | null = null;

let isFirstPoint: boolean = false;

let playerCharacter: Character;
let aiCharacter: Character;

let roundResults: Character[] = [];

const POINTS_TO_WIN_ROUND = 5;
const TOTAL_ROUNDS = 3;

let lastMoveResult: LastMoveResult | null = null;

type LastMoveResult = {
  playerMove: Moves;
  aiMove: Moves;
  result: "draw" | "win" | "lose";
};

export type GameState = {
  round: number;
  userRoundScore: number;
  aiRoundScore: number;
  userWonRounds: number;
  aiWonRounds: number;
  hasGameEnded: boolean;
  hasPlayerWon: boolean;
  aiMove: Moves | null;
  isFirstPoint: boolean;
  roundResults: Character[];
  playerCharacter: Character | null;
  aiCharacter: Character | null;
  lastMoveResult: LastMoveResult | null;
};

export enum Moves {
  QuickJab = 0,
  StrongPunch = 1,
  Defense = 2,
}

export function moveToString(move: Moves): string {
  switch (move) {
    case Moves.QuickJab:
      return "Quick Jab";
    case Moves.StrongPunch:
      return "Strong Punch";
    case Moves.Defense:
      return "Defense";
  }
}

// Check if the player is the winner
function isPlayerWinner(userMove: Moves, aiMove: Moves): boolean {
  // A Quick Jab beats the defense
  if (userMove == Moves.QuickJab && aiMove == Moves.Defense) {
    return true;
  }
  // A Strong Punch overpowers a Quick Jab
  if (userMove == Moves.StrongPunch && aiMove == Moves.QuickJab) {
    return true;
  }
  // Defense beats a Strong Punch
  if (userMove == Moves.Defense && aiMove == Moves.StrongPunch) {
    return true;
  }

  return false;
}

function returnGameState(): GameState {
  return {
    round,
    userRoundScore,
    aiRoundScore,
    userWonRounds,
    isFirstPoint,
    aiWonRounds,
    hasGameEnded,
    hasPlayerWon: userWonRounds === TOTAL_ROUNDS - 1,
    aiMove,
    roundResults,
    playerCharacter,
    aiCharacter,
    lastMoveResult,
  };
}

export function getBlankState(): GameState {
  return {
    round: 0,
    userRoundScore: 0,
    aiRoundScore: 0,
    userWonRounds: 0,
    aiWonRounds: 0,
    hasGameEnded: false,
    hasPlayerWon: false,
    aiMove: null,
    isFirstPoint: false,
    roundResults: [],
    playerCharacter: null,
    aiCharacter: null,
    lastMoveResult: null,
  };
}

// Start a new game
export function startGame(player: Character) {
  round = 0;
  userRoundScore = 0;
  aiRoundScore = 0;
  userWonRounds = 0;
  aiWonRounds = 0;
  hasGameEnded = false;
  aiMove = null;
  isFirstPoint = true;

  lastMoveResult = null;
  playerCharacter = player;

  let remainingCharacters = CHARACTERS.filter(
    (character) => character !== player
  );
  // Pick a random character for the AI which is not the character of the player
  aiCharacter =
    remainingCharacters[Math.floor(Math.random() * remainingCharacters.length)];

  roundResults = [];

  return returnGameState();
}

export function playMove(playerMove: Moves): GameState {
  if (hasGameEnded)
    throw new Error("This game has ended. Please start a new game.");

  isFirstPoint = false;
  // Generate a random move for the AI
  aiMove = Math.floor(Math.random() * 3);

  // Check if its a draw
  if (playerMove === aiMove) {
    lastMoveResult = {
      playerMove,
      aiMove,
      result: "draw",
    };
    return returnGameState();
  }

  //  Determine the winner
  const playerWon = isPlayerWinner(playerMove, aiMove);
  playerWon ? userRoundScore++ : aiRoundScore++;
  lastMoveResult = {
    playerMove,
    aiMove,
    result: playerWon ? "win" : "lose",
  };

  // Check if this round should be over.
  if (
    userRoundScore === POINTS_TO_WIN_ROUND ||
    aiRoundScore === POINTS_TO_WIN_ROUND
  ) {
    if (userRoundScore >= POINTS_TO_WIN_ROUND) {
      userWonRounds++;
      roundResults = [...roundResults, playerCharacter];
    } else {
      aiWonRounds++;
      roundResults = [...roundResults, aiCharacter];
    }

    round++;
    isFirstPoint = true;
    userRoundScore = 0;
    aiRoundScore = 0;
  }
  // Check if the game has ended
  if (userWonRounds === TOTAL_ROUNDS - 1 || aiWonRounds === TOTAL_ROUNDS - 1) {
    hasGameEnded = true;
    isFirstPoint = false;
    // There is no next round
    round--;
  }

  console.log({
    ...lastMoveResult,
    aiRoundScore,
    userRoundScore,
  });

  return returnGameState();
}
