import PumpLogo from "../assets/PumpLogo.webp";
import X from "../assets/XLogo.webp";
import Telegram from "../assets/TelegramLogo.webp";
import { useRecoilValue } from "recoil";
import { gameStateAtom } from "../state/game";
import { PUMP_LINK, TELEGRAM_LINK, TWITTER_LINK } from "../constants/social";

export default function FightingScreenHeader() {
  const gameState = useRecoilValue(gameStateAtom);

  return (
    <div className="fightingScreenHeader">
      <div className="leftHeader">Tate Kickboxing Championship</div>
      <div className="scoreBoardContainer">
        <div className="round">
          <div className="scoreBoardTitle">ROUND</div>
          <div className="bold">{gameState.round + 1} OF 3</div>
        </div>
        <div className="scoreBoard">
          <div>
            <div className="scoreBoardTitle">{gameState.aiCharacter?.name}</div>
            <div className="bold">{gameState.aiRoundScore}</div>
          </div>
          <div className="bold">-</div>
          <div>
            <div className="scoreBoardTitle">
              {gameState.playerCharacter?.name}
            </div>
            <div className="bold">{gameState.userRoundScore}</div>
          </div>
        </div>
        <div className="roundResult">
          <div className="scoreBoardTitle">ROUND RESULT</div>
          <div className="winnerLog">
            {gameState.roundResults.map((character, index) => (
              <img key={index} src={character.faceImg} alt="win" />
            ))}
          </div>
        </div>
      </div>
      <div className="rightHeader">
        $TKC
        <div className="links">
          <a href={PUMP_LINK} target="_blank" rel="noreferrer" className="link">
            <img src={PumpLogo} alt="Pump Logo" />
          </a>
          <a
            href={TWITTER_LINK}
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            <img src={X} alt="X Logo" />
          </a>
          <a
            href={TELEGRAM_LINK}
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            <img src={Telegram} alt="Telegram Logo" />
          </a>
        </div>
      </div>
    </div>
  );
}
