import backgroundImage from "../assets/BoxingRing.webp";
import startScreenImage from "../assets/startScreen.webp";

export default function Background({
  blur,
  isStartScreen,
}: {
  blur?: boolean;
  isStartScreen?: boolean;
}) {
  return (
    <div
      style={{
        backgroundImage: `url(${
          isStartScreen ? startScreenImage : backgroundImage
        })`,
      }}
      className={`bg ${blur ? "blur" : ""}`}
    />
  );
}
