import React from "react";
import styles from "../styles/looseScreen.module.css";
import { useGame } from "../game/engine/useGameEngine";

export default function LooseScreen() {
  const { replay } = useGame();
  return (
    <div className={styles.container}>
      <div className={styles.bloodSplatter}></div>
      <div className={styles.textContainer}>
        <div>You Lost</div>
        <button className={"button"} onClick={replay}>
          Retry
        </button>
      </div>
    </div>
  );
}
