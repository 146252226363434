import { useState } from "react";
import { Moves } from "../game/engine/gameEngine";
import { useGame } from "../game/engine/useGameEngine";
import { motion } from "framer-motion";

import strongPunchSfx from "../assets/sounds/strong_punch.mp3";
import quickJabSfx from "../assets/sounds/quick_jab.mp3";
import defendSfx from "../assets/sounds/defence.mp3";
import useSound from "use-sound";

export default function Actions() {
  const { playMove } = useGame();

  const [playStrongPunch] = useSound(strongPunchSfx);
  const [playDefence] = useSound(defendSfx);
  const [playQuickJab] = useSound(quickJabSfx);

  const [canAct, updateCanAct] = useState(true);

  const justActed = () => {
    updateCanAct(false);
    setTimeout(() => {
      updateCanAct(true);
    }, 1700);
  };

  if (canAct) {
    return (
      <motion.div
        className="actions"
        animate={{
          opacity: [0, 100],
          scaleY: [0, 1],
          transition: { duration: 0.5 },
        }}
      >
        <div
          className="punch actionButton"
          onClick={() => {
            playMove(Moves.StrongPunch);
            playStrongPunch();
            justActed();
          }}
        >
          PUNCH
        </div>
        <div
          className="quick actionButton"
          onClick={() => {
            playMove(Moves.QuickJab);
            playQuickJab();
            justActed();
          }}
        >
          KICK
        </div>
        <div
          className="defend actionButton"
          onClick={() => {
            playMove(Moves.Defense);
            playDefence();
            justActed();
          }}
        >
          DEFEND
        </div>
      </motion.div>
    );
  } else {
    return null;
  }
}
