import { atom } from "recoil";

export interface Character {
  name: string;
  faceImg: string;
  bodyImg: string;
  winningImg?: string;
}

// variants={{
//       resting: LinearMove(false),
//       lose: Lose,
//       win: Win,
//     }}

export enum CharacterAnimations {
  Resting = "resting",
  Lose = "lose",
  Win = "win",
}

export const userCharacterAnimationAtom = atom<CharacterAnimations>({
  key: "userCharacterAnimation",
  default: CharacterAnimations.Resting,
});

export const aiCharacterAnimationAtom = atom<CharacterAnimations>({
  key: "aiCharacterAnimation",
  default: CharacterAnimations.Resting,
});

// The character the user selects
export const userSelectedCharacterAtom = atom<Character>({
  key: "userSelectedCharacter",
  default: undefined,
});

// AI Selected character
export const aiSelectedCharacterAtom = atom<Character>({
  key: "aiSelectedCharacter",
  default: undefined,
});
