import { useRecoilState, useSetRecoilState } from "recoil";
import { activeScreenAtom, gameStateAtom, ScreensEnum } from "../../state/game";
import { Character, userSelectedCharacterAtom } from "../../state/character";
import { aiMoveState, playMoveState } from "../../recoil/moves";

import * as GameEngine from "./gameEngine";
import { useEffect } from "react";
import useSound from "use-sound";

import bellSfx from "../../assets/sounds/bell.mp3";

export function useGame() {
  const updateActiveScreen = useSetRecoilState(activeScreenAtom);
  const [gameState, updateGameState] = useRecoilState(gameStateAtom);

  const setPlayerMove = useSetRecoilState(playMoveState);
  const setAiMove = useSetRecoilState(aiMoveState);

  const [playBell] = useSound(bellSfx);

  const replay = () => {
    updateActiveScreen(ScreensEnum.START_SCREEN);
    updateGameState(GameEngine.getBlankState());
  };

  useEffect(() => {
    if (gameState.hasGameEnded) {
      gameState.hasPlayerWon
        ? updateActiveScreen(ScreensEnum.WIN_SCREEN)
        : updateActiveScreen(ScreensEnum.LOSE_SCREEN);
    }

    if (gameState.lastMoveResult) {
      setPlayerMove(gameState.lastMoveResult?.playerMove);
      setAiMove(gameState.lastMoveResult?.aiMove);
    }

    if (gameState.isFirstPoint) {
      playBell();
    }
  }, [gameState, updateActiveScreen, updateGameState]);

  // Start a game
  const startGame = (player: Character) => {
    let updatedGameState = GameEngine.startGame(player);
    updateGameState(updatedGameState);
    updateActiveScreen(ScreensEnum.FIGHT_SCREEN);
  };

  const playMove = (move: GameEngine.Moves) => {
    let newGameState = GameEngine.playMove(move);
    updateGameState(newGameState);
    // Play trigger the animations here
  };

  return { startGame, playMove, replay };
}
