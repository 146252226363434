import buggattiFace from "../assets/characters/face/bugatti.png";
import piersFace from "../assets/characters/face/piers.png";
import policeFace from "../assets/characters/face/police.png";
import neonFace from "../assets/characters/face/neon.png";
import tateFace from "../assets/characters/face/tate.png";
import tristanFace from "../assets/characters/face/tristan.png";

import buggattiBody from "../assets/characters/body/bugatti.png";
import piersBody from "../assets/characters/body/piers.png";
import policeBody from "../assets/characters/body/police.png";
import neonBody from "../assets/characters/body/neon.png";
import tateBody from "../assets/characters/body/tate.png";
import tristanBody from "../assets/characters/body/tristan.png";

import { Character } from "../state/character";

export const CHARACTERS: Character[] = [
  {
    name: "BUGATTI",
    faceImg: buggattiFace,
    bodyImg: buggattiBody,
    winningImg: "https://i.ibb.co/SmGnW9m/lOGO.png",
  },
  {
    name: "PIERS",
    faceImg: piersFace,
    bodyImg: piersBody,
    winningImg: "https://i.ibb.co/SmGnW9m/lOGO.png",
  },
  {
    name: "POLICE",
    faceImg: policeFace,
    bodyImg: policeBody,
    winningImg: "https://i.ibb.co/SmGnW9m/lOGO.png",
  },
  {
    name: "NEON",
    faceImg: neonFace,
    bodyImg: neonBody,
    winningImg: "https://i.ibb.co/SmGnW9m/lOGO.png",
  },

  {
    name: "ANDREW",
    faceImg: tateFace,
    bodyImg: tateBody,
    winningImg: "https://i.ibb.co/SmGnW9m/lOGO.png",
  },
  {
    name: "TRISTAN",
    faceImg: tristanFace,
    bodyImg: tristanBody,
    winningImg: "https://i.ibb.co/SmGnW9m/lOGO.png",
  },
];
