import { atom } from "recoil";
import { GameState, getBlankState } from "../game/engine/gameEngine";

export enum ScreensEnum {
  START_SCREEN,
  CHARACTER_SELECTION,
  FIGHT_SCREEN,
  WIN_SCREEN,
  LOSE_SCREEN,
}

export const activeScreenAtom = atom<ScreensEnum>({
  key: "activeScreenAtom",
  default: ScreensEnum.START_SCREEN,
});

export const gameStateAtom = atom<GameState>({
  key: "gameStateAtom",
  default: getBlankState(),
});
