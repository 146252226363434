import React from "react";
import Spacer from "./Spacer";
import Header from "./Header";
import Buttons from "./Buttons";
import PumpLogo from "../assets/PumpLogo.webp";
import Belt from "../assets/belt.png";
import X from "../assets/XLogo.webp";
import Telegram from "../assets/TelegramLogo.webp";
import { useSetRecoilState } from "recoil";
import { ScreensEnum, activeScreenAtom } from "../state/game";
import { PUMP_LINK, TELEGRAM_LINK, TWITTER_LINK } from "../constants/social";

export default function StartScreen() {
  const setActiveScreen = useSetRecoilState(activeScreenAtom);

  return (
    <div className="content startScreen">
      <div>
        <Spacer height={100} />
        <Header />
      </div>
      <div className="bottomSection">
        <div className="buttons">
          <Buttons
            text="PLAY NOW"
            onClick={() => setActiveScreen(ScreensEnum.CHARACTER_SELECTION)}
          />
          <Spacer height={35} />
          <Buttons
            text="BUY $TKC"
            onClick={() => {
              window.open(PUMP_LINK, "_blank");
            }}
          />
        </div>
        <div className="links">
          <a href={PUMP_LINK} target="_blank" rel="noreferrer" className="link">
            <img src={PumpLogo} alt="Pump Logo" />
          </a>
          <a
            href={TWITTER_LINK}
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            <img src={X} alt="X Logo" />
          </a>
          <a
            href={TELEGRAM_LINK}
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            <img src={Telegram} alt="Telegram Logo" />
          </a>
        </div>
        <div
          className="beltContainer"
          style={{
            height: "50vh",
            width: "100%",
          }}
        ></div>
      </div>
    </div>
  );
}
