import { atom } from "recoil";
import { Moves } from "../game/engine/gameEngine";

export const playMoveState = atom<Moves | undefined>({
  default: undefined,
  key: "playMoveState",
});

export const aiMoveState = atom<Moves | undefined>({
  default: undefined,
  key: "aiMoveState",
});
