import { useRecoilState } from "recoil";
import "./App.css";
import Background from "./components/Background";
import FightingScreen from "./components/FightingScreen";
import StartScreen from "./components/StartScreen";
import CharacterSelection from "./game/react/CharacterSelection";
import { ScreensEnum, activeScreenAtom } from "./state/game";
import { useEffect } from "react";

import bgSfx from "./assets/sounds/bg.mp3";
import winnerSfx from "./assets/sounds/winner.mp3";
import useSound from "use-sound";
import { useMediaQuery } from "react-responsive";

function App() {
  const [activeScreen, setActiveSreen] = useRecoilState(activeScreenAtom);
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const [playBg, { stop }] = useSound(bgSfx, { volume: 0.2, loop: true });
  const [playWin, { stop: stopWin }] = useSound(winnerSfx, {
    volume: 0.2,
    loop: true,
  });

  useEffect(() => {
    if (activeScreen === ScreensEnum.FIGHT_SCREEN) {
      playBg();
    } else {
      stop();
    }

    if (activeScreen === ScreensEnum.WIN_SCREEN) {
      playWin();
    } else {
      stopWin();
    }
  }, [activeScreen]);

  const getActiveScreen = () => {
    switch (activeScreen) {
      case ScreensEnum.START_SCREEN:
        return <StartScreen />;
      case ScreensEnum.CHARACTER_SELECTION:
        return <CharacterSelection />;
      case ScreensEnum.FIGHT_SCREEN:
        return <FightingScreen />;
      case ScreensEnum.WIN_SCREEN:
        return <FightingScreen />;
      case ScreensEnum.LOSE_SCREEN:
        return <FightingScreen />;
    }
  };

  return (
    <div className="App">
      {isMobile ? (
        <div className="mobileWarning">
          This site is build for bigger screens please visit us on a larger
          device.
        </div>
      ) : (
        <>
          <Background
            isStartScreen={activeScreen === ScreensEnum.START_SCREEN}
            blur={activeScreen === ScreensEnum.CHARACTER_SELECTION}
          />
          {getActiveScreen()}
        </>
      )}
    </div>
  );
}

export default App;
